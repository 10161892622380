import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import '../styles/positions.css'
import Layout from "../components/layout"

const IndexPage = () => {
    return (
        <Layout>
            <div id="container-title">
                <div id="wrapper-conteudo">
                    <div  id="conteudo-100-col">
                        <p data-sal="slide-up" data-sal-delay="0" data-sal-duration="800" data-sal-easing="ease" class="titulo-texto gt-light">Open positions 🎯</p>
                        <p data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="texto-intro black gt-light">For now we have no positions available. </p>
                    </div>
                    <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease"  id="conteudo-100-col2">
                    

                    <div  class="left-col col-50 col" >
                    <p class="texto black gt-light"> Send us a self-aplication!</p>
                    </div>
                    <div class="right-col col-50 col vertical">
                    <a style={{textDecoration: 'none'}} href="mailto:geral@fortydegrees.pt?subject=Hello,%20I%20have%20a%20self%20application" target="_blank">  <button class="btn-next gt-regular white">Send </button></a>
                    </div>
                </div>
            </div>    
            </div>  
        </Layout>

    )
}



export default IndexPage
